   <script>
import { minLength, numeric, required } from "vuelidate/lib/validators";
import validationMessages from '@/components/validations'
import Advertising from '@/services/Advertising';
import Monitor from '@/services/Monitor';
import "flatpickr/dist/flatpickr.css";
import Swal from "sweetalert2";

export default {
    components: { validationMessages },
    props: ['adsData'],
    watch: {
        adsData: function(newVal) {
            if (newVal) {
                this.team_id = newVal.team_id;
                this.content = newVal.content;
            } else {
                this.team_id = '';
                this.content = '';
            }
            this.submitted = false;
            this.$v.$reset();
        }
    },
    data() {
        return {
            tenant: process.env.VUE_APP_TENANT,
            tenants: '',
            tenants_options: [],
            team_id: '',
            content: '',
            showModal: false,
            submitted: false,
            tryingToSubmit: false,
            showLoader: false,
            csrf_token: localStorage.getItem('csrf_token'),
            tryingToEdit: false,
        };
    },
    validations: {
        team_id: { required },
        content: { required },
    },
    
    async created() {
        await this.getTenants();
    },
    computed: {
        filteredTeamIdOptions() {
            const existingTeamIds = this.adsData.map(ad => ad.team_id);
            return this.tenants_options.filter(option => !existingTeamIds.includes(option.value));
        }
    },
   
    methods: {
        callModalAddAppAds(data) {
            this.showModal = true;
            this.resetForm();
        },
        closeModal() {
            this.showModal = false;
        },
        resetForm() {
            this.team_id = '';
            this.content = '';
            this.submitted = false;
            this.$v.$reset();
        },
        async getTenants() {
            if (this.tenant == 'MON') {
                try {
                    const response = await Monitor.getTenants();
                    this.tenants_options = response.data.tenants.map(tenant => ({ value: tenant, text: tenant }));
                } catch (error) {
                    console.error("Error fetching tenants:", error);
                }
            }
        },
        async editAppAds() {
            this.tryingToEdit = true;
            this.submitted = true;
            this.$v.$touch();

            if (this.$v.$invalid) {
                this.tryingToEdit = false;
                return;
            } else {
                let formData = new FormData();
                formData.append('team_id', this.team_id);
                formData.append('content', this.content);

                if (this.tenant == 'MON') {
                    formData.append('tenants', this.tenants);
                }

                try {
                    const response = await Advertising.addAppAds(formData);
                    const error = typeof response.error !== 'undefined' ? response.error : false;

                    if (!error) {
                        this.successmsg("App Ads successfully created");
                        this.closeModal();
                    } else {
                        this.failedmsg("Failed to create app ads");
                    }
                } catch (error) {
                    this.failedmsg(error.response.data.error ? error.response.data.error : "App ads not updated");
                } finally {
                    this.refreshData();
                    this.tryingToSubmit = false;
                }
            }
        },
        refreshData() {
            this.$emit('onRefresh');
        }
    }
};
</script> 

<template>
    <b-modal id="add_app_ads" size="xl" v-model="showModal" title="App Ads Setup" title-class="font-18">
      <form @submit.prevent="editAppAds">
        <div class="row">
          <div class="col">
            <div v-if="$v.$error && submitted" class="text-danger">Complete all fields</div>
            <b-tab title="App Ads Setup" active title-item-class="mb-2">
              <b-card-text>
                <div class="row">
                  <div class="col-6">
                    <b-form-group v-if="tenant == 'MON'" label="Team" label-for="formrow-tenant-input" class="mb-3">
                      <b-form-select v-model="team_id" class="form-select" :options="filteredTeamIdOptions" :class="{ 'is-invalid': submitted && $v.team_id.$error }"></b-form-select>
                      <validationMessages v-if="submitted" :fieldName="'Team ID'" :validationName="$v.team_id"></validationMessages>
                    </b-form-group>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <b-form-group label="Content" label-for="formrow-content-input" class="mb-3">
                      <b-form-textarea v-model.trim="content" :class="{ 'is-invalid': submitted && $v.content.$error }"></b-form-textarea>
                      <validationMessages v-if="submitted" :fieldName="'Content'" :validationName="$v.content"></validationMessages>
                    </b-form-group>
                  </div>
                </div>
                <input type="hidden" name="csrf_token" v-model="csrf_token">
              </b-card-text>
            </b-tab>
          </div>
        </div>
      </form>
      <template v-slot:modal-footer>
        <b-button variant="light" @click="closeModal">Close</b-button>
        <b-button variant="primary" @click="editAppAds" :disabled="tryingToSubmit || showLoader">
          <b-spinner v-show="tryingToSubmit" small></b-spinner>Submit
        </b-button>
      </template>
    </b-modal>
</template>
