<script>
  import Layout from "../../layouts/main";
  import PageHeader from "@/components/page-header";
  import appConfig from "@/app.config";
  import Advertising from '@/services/Advertising';
  import ModalEditAppAds from "@/components/modals/advertising/modalEditAppAds";
  import ModalAddAppAds from "@/components/modals/advertising/modalAddAppAds";
  import Swal from "sweetalert2";


  export default {
  components: { Layout, PageHeader, ModalEditAppAds,ModalAddAppAds},
  page: {
      title: "App Ads Setup",
      meta: [
        {
          name: "description",
          content: appConfig.description,
        },
      ],
  },
  data() {
    return {
        tenant: process.env.VUE_APP_TENANT,
        display_team:"display:none",
        error: null,
        filterData: false,
        filterDataAll: false,

        tableData: [],
        title: "App Ads Setup",
        items: [
          {
            text: "Advertising",
          },
          {
            text: "App Ads Setup",
            active: true,
          },
        ],
        filterInput: {
        },
        isBusy: false,
        totalRows: 0,
        currentPage: 1,
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        filter: null,
        filterOn: [],
        sortBy: "team_id",
        sortDesc: true,
        adsData:[],
        appAdsData:[],
        fields: [
           {
              key: "team_id",
              label: "Team ID",
              visible:false,

            },
            {
              key: "content",
              label: "Content",
              visible:true,
            },
            {
              key: "timestamp",
              label: "Timestamp",
              visible:true,
            },

            {
               key: "Action",
               label: "Action",
               thStyle: { width: "12%" },
            }
        ],

        };
  },

  async created() {
    this.getAppAds();
  },

  filters: {
         truncate: function (text, length, suffix) {
              if(text !== null){
                  if (text.length > length) {
                      return text.substring(0, length) + suffix;
                  } else {
                      return text;
                  }
              } else {
                return '';
              }
          },

      },
 methods: {

      /**
     * Search the table data with search input
     */
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },

      onFilteredAll(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRowsAll = filteredItems.length;
        this.currentPageAll = 1;
      },
      async getAppAds(){
  try {
    this.filterData = true;
    this.isBusy = true;
    var filters = '';
    const response = await Advertising.getAppAds(filters);
    this.appAdsData=response.data.data;
    this.tableData = response.data.data.map(item => ({ ...item, expanded: false })); // Add expanded property
    this.totalRows = this.tableData.length;
    this.isBusy = false;
  } catch (error) {
    this.isBusy = false;
    this.error = error.response.data.error ? error.response.data.error : "";
    this.tableData = [];
    this.totalRows = 0;
  }
},

   truncateContent(content) {
          if (!content) return '';
          const words = content.split(' ');
          if (words.length > 3) {
            return words.slice(0, 3).join(' ') + '...';
          }
          return content;
        },
       

        toggleContent(item) {
          item.expanded = !item.expanded;
        },

     getFilters(){
       var filter_string = '';
       return filter_string;
     },


      callModalEditAppAds(data) {
        this.$bvModal.show("edit_app_ads");
        this.adsData = data
      },

      callModalAddAppAds() {
        this.$bvModal.show("add_app_ads");
        this.adsData =this.appAdsData
      },

      deleteAppAds(team_id){
        Swal.fire({
          title: "Are you sure?",
          text: "Remove From App Ads!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#34c38f",
          cancelButtonColor: "#f46a6a",
          confirmButtonText: "Yes!",
        }).then((result) => {
          if (result.value) {
                Advertising.deleteAppAds(team_id)
                    .then((response) => {
                      const res   = response.data.data  ? response.data.data  : false;
                      const error = response.data.error ? response.data.error : '';
                      if(res && error==''){
                        this.getAppAds();
                        this.successmsg("App Ads Successfully Removed")
                      }else{
                        this.failedmsg("App Ads Removal Failed")
                      }
                    })
                    .catch(error => {
                      this.failedmsg('Fail!',error)
                });
          }
        });
      },
    
         resetFiltersAll(){
          for (const prop of Object.getOwnPropertyNames(this.filterInputAll)) {
              if(Array.isArray(this.filterInputAll[prop])){
                  this.filterInputAll[prop] = [];
              }else{
                  this.filterInputAll[prop] = "";
                  let refName = prop+'Autocomplete';
                  if(this.$refs[refName]){
                      this.$refs[refName].inputValue = "";
                  }
              }
          }
          this.tableData = [];
      },

  },
  middleware: "authentication",
};
</script>


<template>
  <Layout>
    <PageHeader :title="title" :items="items" :fields="fields"/>
    <div class="row">
      <div class="col-12">

        <div class="card" v-show="filterData">
          <div class="card-body">
            <a href="javascript:void(0);" class="btn btn-success waves-effect waves-light"
                 @click="callModalAddAppAds">
                <i class="mdi mdi-plus me-2"></i>Create App Ads
            </a>

            <div class="row mt-4">
              <div class="col d-inline-flex align-items-center" >
                <h4 class="card-title m-0">Total App Ads Setup: {{totalRows}}</h4>
              </div>
            </div>

            <div class="row mt-4" v-show="filterData">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                            v-model="perPage"
                            size="sm"
                            :options="pageOptions"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                        id="deals-table_filter"
                        class="dataTables_filter text-md-end"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                            v-model="filter"
                            type="search"
                            placeholder="Search..."
                            class="form-control form-control-sm ms-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>

            <!-- Table -->
            <div class="table-responsive mb-0 font-size-12" v-show="filterData">
              <b-table
                      :items="tableData"
                      :busy="isBusy"
                      :fields="fields"
                      :visible="fields.visible"
                      responsive="sm"
                      :per-page="perPage"
                      :current-page="currentPage"
                      :sort-by.sync="sortBy"
                      :sort-desc.sync="sortDesc"
                      :filter="filter"
                      :filter-included-fields="filterOn"
                      @filtered="onFiltered"
                      show-empty
                      empty-text="No Data Found"
              >

                <template  v-slot:cell(team_id)="data" >
                    <div>{{ data.item.team_id  }}</div>
                </template>
                <template #cell(content)="row">
                  <span @click="toggleContent(row.item)" style="cursor: pointer;">
                    {{ row.item.expanded ? row.item.content : truncateContent(row.item.content) }}
                  </span>
                </template>
              
  <template v-slot:cell(timestamp)="data">
                   <div>{{ data.item.timestamp }}</div>
                </template>


                <template v-slot:cell(action)="data">
                  <ul class="list-inline mb-0">
                    <li class="list-inline-item">
                      <a href="javascript:void(0);" class="px-2 text-primary" title="Edit Deal" @click="callModalEditAppAds(data.item)">
                        <i class="uil uil-pen font-size-18"></i>
                      </a>
                    </li>
                    <li class="list-inline-item">
                      <a href="javascript:void(0);" class="px-2 text-danger" title="Remove App Ads" @click="deleteAppAds(data.item.team_id)">
                        <i class="uil uil-trash-alt font-size-18"></i>
                      </a>
                    </li>
                  </ul>
                </template>

                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
                </template>

              </b-table>
            </div>
             <!--Table end -->

            <div class="row" v-show="filterData">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                            v-model="currentPage"
                            :total-rows="totalRows"
                            :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>

         </div>
        </div>



      </div>
    </div>


    <!-- MODALS -->
  
    <ModalEditAppAds :adsData="adsData" @onRefresh="getAppAds()"></ModalEditAppAds>
    <ModalAddAppAds :adsData="adsData" @onRefresh="getAppAds()" ></ModalAddAppAds>

    <!-- /MODALS -->

  </Layout>
</template>
