<script>
    import {minLength, numeric, required, maxDecimals, decimal} from "vuelidate/lib/validators";
    import validationMessages from '@/components/validations'
    import Advertising from '@/services/Advertising';
    import Swal from "sweetalert2";
   

    export default {
        components:{ validationMessages},
        props: ['adsData'],
        watch: {
            adsData: function(newVal) {
                this.team_id = newVal.team_id;
                this.content = newVal.content;

            }
        },

        data() {
            return {
                team_id:  '',
                content: '',
                showModal: false,
                submitted: false,
                tryingToSubmit: false,
                showLoader: false,
                csrf_token: localStorage.getItem('csrf_token'),
                tryingToEdit: false,
                 
            };
        },

        validations: {
            content: {
                required,
            },
        
         },


        methods: {
            closeModal() {
                this.showModal = false;
                this.submitted = false;
                this.tryingToEdit = false;
                
            },
            
 async editAppAds(){
                this.tryingToEdit = true;
                    this.submitted = true;
                    this.$v.$touch()

                    if (this.$v.$invalid) {
                        this.tryingToEdit = false;
                        return;
                    } else {
                         let formData = new FormData();
                        
                         formData.append('content', this.content);
                         try {
                             await Advertising.editAppAds(this.team_id,{
            content: this.content,
           csrf_token: this.csrf_token

          }).then((response) => {

                                 const error   =  typeof response.error !== 'undefined' ? response.error  : false;

                                 if(error==''){
                                     this.successmsg("App Ads successfully updated");
                                     this.closeModal();
                                 }else{
                                     this.failedmsg("Failed to update app ads")
                                 }

                             }).catch(error => {
                                 this.error = error.response.data.error ? error.response.data.error : "";
                                 this.failedmsg(this.error)
                             }).finally(() => {
                                 this.refreshData()
                                 this.tryingToSubmit = false;
                             })
                         } catch (error) {
                             this.error = error.response.data.error ? error.response.data.error : "";
                             this.failedmsg("Deal not updated");
                         }
                    }
                 this.tryingToSubmit = false;
             },
             refreshData() {
                this.$emit('onRefresh') //event from parent
             },


        }
    };


</script>

<template>
      <b-modal id="edit_app_ads" size="xl" v-model="showModal" title="Edit App Ads" title-class="font-18">
         <form @submit.prevent="editAppAds()">
            <div class="row" v-if="adsData">
             <div v-if="$v.$error" class="text-danger">Complete all fields</div>

                   <b-card-text>
                             <div class="row">
                                <div class="col">
                                    <p class=" mb-2"><span class="text-strong">Team ID:</span> {{adsData.team_id}}</p>
                                     <b-form-group label="Content" label-for="formrow-name-input" class="mb-3">
                                            <b-form-textarea  v-model.trim="content"  :class="{ 'is-invalid': submitted && $v.content.$error}"></b-form-textarea>
                                            <validationMessages v-if="submitted" :fieldName="'Content'" :validationName="$v.content"></validationMessages>
                                      </b-form-group>
                                      <input type="hidden" name="csrf_token" v-model="csrf_token">

                                </div> 
                            </div>
                        </b-card-text>
                   </div>

        </form>
  <template v-slot:modal-footer>
            <b-button variant="light" @click="closeModal">Close</b-button>
            <b-button variant="primary" @click="editAppAds" :disabled="tryingToSubmit || showLoader">
                 <b-spinner v-show="tryingToSubmit" small></b-spinner>Submit
            </b-button>
        </template>

    </b-modal>
</template>

